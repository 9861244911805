<template>
  <div class="EditResidentInfo">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item label="真实姓名" prop="realName">
        <v-input
          :width="width"
          placeholder="请输入真实姓名"
          v-model="form.realName"
        ></v-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <v-input
          :width="width"
          :disabled="isReadonlyPhone"
          placeholder="请输入手机号码"
          v-model="form.phone"
        ></v-input>
        <v-button
          class="btn"
          @click="isReadonlyPhone = false"
          text="变更"
        ></v-button>
      </el-form-item>
      <el-form-item label="证件类型" prop="cardType">
        <v-select
          :width="width"
          :options="cardTypeList"
          v-model="form.cardType"
        />
      </el-form-item>
      <el-form-item label="证件号" prop="cardNum">
        <v-input
          :width="width"
          :disabled="isReadonlyCardNum"
          placeholder="请输入证件号"
          v-model="form.cardNum"
        ></v-input>
        <v-button
          class="btn"
          @click="isReadonlyCardNum = false"
          text="变更"
        ></v-button>
      </el-form-item>
      <el-form-item label="是否自主" prop="isOwner">
        <v-select
          :width="width"
          :options="isOwnersOps"
          v-model="form.isOwner"
        />
      </el-form-item>
      <el-form-item label="房屋用途" prop="usageStatus">
        <v-select
          :width="width"
          :options="usageOps"
          v-model="form.usageStatus"
        />
      </el-form-item>
      <el-form-item label="人户关系" prop="censusRegisterStatus">
        <v-select
          :width="width"
          :options="relationOps"
          v-model="form.censusRegisterStatus"
        />
      </el-form-item>
      <el-form-item label="户籍人口" prop="isCensusRegister">
        <v-select
          :width="width"
          :options="registeredPopulationOps"
          v-model="form.isCensusRegister"
        />
      </el-form-item>
      <el-form-item label="房号选择" prop="spaceId">
        <v-cascader
          :width="width"
          placeholder="默认表示全部"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
          @change="sectionChange"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  getPdfUser,
  submitPdfUser,
  getCardType,
  getBuildListURL,
} from "./api.js";
import {
  isOwnersOps,
  usageOps,
  relationOps,
  registeredPopulationOps,
} from "./map.js";
export default {
  name: "EditResidentInfo",
  data() {
    return {
      width: 250,
      submitConfig: {
        queryUrl: getPdfUser,
        submitUrl: submitPdfUser,
      },
      isReadonlyPhone: true,
      isReadonlyCardNum: true,
      authSpaceIds: [], //房号id
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      form: {
        id: "",
        realName: "", //真实姓名
        phone: "", //手机号码
        cardType: "", //证件类型
        cardNum: "", //证件号
        isOwner: "", //是否自主
        usageStatus: "", //房屋用途
        isCensusRegister: "", //户籍人口
        censusRegisterStatus: "", //人户关系
        spaceId: "",
      },
      isOwnersOps: isOwnersOps(), //是否自主
      usageOps: usageOps(), //房屋用途
      registeredPopulationOps: registeredPopulationOps(), //户籍人口
      relationOps: relationOps(), //人户关系
      cardTypeList: [], //证件类型数据
      sectionOps: [], //区域数据
      spacePathIds: [],
    };
  },
  watch: {
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.spaceId = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  async created() {
    const { id } = this.$route.query;
    await this.getBuildList();
    await this.getCardType();
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ id: id });
      });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  mounted() {},
  methods: {
    //获取房号
    async getBuildList() {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=1`
      );
      if (res.code === 200) {
        this.sectionOps = this.dealDataAddParentIds(res.data);
        console.log("this.set----->", this.sectionOps);
      }
    },
    //获取证件类型
    getCardType() {
      const params = {
        code: "identityType",
      };
      this.$axios.post(getCardType, this.$qs.stringify(params)).then((res) => {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.dictKey;
          item.label = item.dictValue;
        });
        this.cardTypeList = data;
      });
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = String(data[key]);
      });
      const ids = this.form.spaceId ? this.form.spaceId.split(",") : [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.authSpaceIds = [[...item.treeIds]];
        }
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less">
.el-cascader__dropdown {
  .el-cascader-panel {
    min-height: 300px !important;
    max-height: 600px !important;
  }
}

/** end */
</style>
<style lang="less" scoped>
.EditResidentInfo {
  box-sizing: border-box;
  height: 100%;
  .btn {
    margin-left: 10px;
  }
}
</style>
