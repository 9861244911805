import { mapHelper } from "@/utils/common.js";

// 是否自主
const isOwners = [
  {
    value: "0",
    label: "否",
  },
  {
    value: "1",
    label: "是",
  },
];

const { map: isOwnersMap, setOps: isOwnersOps } = mapHelper.setMap(isOwners);
//房屋用途
const usage = [
  {
    value: "0",
    label: "空关",
  },
  {
    value: "1",
    label: "自主",
  },
  {
    value: "2",
    label: "出租",
  },
  {
    value: "3",
    label: "群租",
  },
];

const { map: usageMap, setOps: usageOps } = mapHelper.setMap(usage);
// 人户关系
const relation = [
  {
    value: "0",
    label: "未知",
  },
  {
    value: "1",
    label: "人户一致",
  },
  {
    value: "2",
    label: "人在户不在",
  },
  {
    value: "3",
    label: "户在人不在",
  },
  {
    value: "4",
    label: "流动人口",
  },
  {
    value: "5",
    label: "境外",
  },
  {
    value: "6",
    label: "集体户",
  },
];

const { map: relationMap, setOps: relationOps } = mapHelper.setMap(relation);
//户籍人口
const registeredPopulation = [
  {
    value: "0",
    label: "不详",
  },
  {
    value: "1",
    label: "常口",
  },
];
const { map: registeredPopulationMap, setOps: registeredPopulationOps } =
  mapHelper.setMap(registeredPopulation);
export {
  isOwnersOps,
  isOwnersMap,
  usageOps,
  usageMap,
  relationOps,
  registeredPopulationOps,
};
