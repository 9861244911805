//列表接口
const getQueryPdfUserList = `/gateway/hc-portal/import/queryPdfUserList`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;
//详情
const getPdfUser = `/gateway/hc-portal/import/getPdfUser`;
//提交
const submitPdfUser = `/gateway/hc-portal/import/submitPdfUser`;
//证件类型
const getCardType = `/gateway/hc-portal/szdz/dictList`;
//删除
const delPdfUser = `/gateway/hc-portal/import/delPdfUser`;
export {
  getQueryPdfUserList,
  getBuildListURL,
  getPdfUser,
  submitPdfUser,
  getCardType,
  delPdfUser,
};
